import React, { useState } from "react"
import { useForm } from "@formspree/react"
import validate from "../constants/validate-contact"

//import Button from "../components/Button.js";
import Headings from "./Headings.js";

import styles from "../styles/style.js";

const GetInTouch = () => {
  const [state, handleSubmit] = useForm(process.env.GATSBY_JOIN_US_FORM)
  const [values, setValues] = useState({
    company: "",
    email: "",
    contact: "",
    message: "",
  })
  const [errors, setErrors] = useState({
    company: "",
    email: "",
    contact: "",
    message: "",
  })
  const [username, setUsername] = useState({
    name: "",
  })

  const handleChange = event => {
    const { name, value } = event.target
    setValues({
      ...values,
      [name]: value,
    })
    //console.log("values: ", values)
  }
  const manageSubmit = e => {
    e.preventDefault()

    // other logic or error checking logic here
    setErrors(validate(values))
    //console.log("errors: ", errors)
    //console.log("values: ", values)

    if (
      values.company.length > 0 &&
      values.contact.length > 0 &&
      values.email.length > 0 &&
      values.message.length > 0
    ) {
      //console.log("values in if statement: ", values)
      setUsername(values.contact)
      handleSubmit(e)
      resetForm()
      //console.log("Submitted successfully")
    }
  }

  const resetForm = () => {
    values.company = ""
    values.contact = ""
    values.email = ""    
    values.message = ""
  }

  return (
    <div
      id="get-started"
      className={`bg-[#ffffff] ${styles.paddingX} ${styles.flexCenter}`}
    >
      <div className={`${styles.boxWidth}`}>
        <section className="text-gray-700 body-font relative  mb-10">
          <div className="mt-6 mb-4">
            <Headings
              titleTop="Get started"
              titleBottom="let's connect"
              description="Intact Electrical realise that collaborating  with other organisations can create a synergy that might not be possible when working alone. Partner with us and let's support and strengthen each other."
            />
          </div>

          <div className="flex md:flex-nowrap flex-wrap mt-6">
            <div className="lg:w-2/3 md:w-1/2 bg-gray-300 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.4301606075705!2d-0.6668396229965717!3d51.52366960951417!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487663582a0c39cb%3A0xf3348ec6ca3a6548!2s46%20Nursery%20Rd%2C%20Taplow%2C%20Maidenhead%20SL6%200JZ!5e0!3m2!1sen!2suk!4v1685638027820!5m2!1sen!2suk"
                width="100%"
                height="100%"
                className="absolute inset-0"
                title="map"
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                style={{ filter: "grayscale(1) contrast(1) opacity(0.8)" }}
              ></iframe>
              <div className="bg-white relative flex flex-wrap py-6 rounded shadow-md">
                <div className="lg:w-1/2 px-6">
                  <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">
                    ADDRESS
                  </h2>
                  <p className="mt-1">
                    46 Nursery Rd, Taplow, Maidenhead, UK. You can use the email
                    link or the form to contact us
                  </p>
                </div>
                <div className="lg:w-1/2 px-6 mt-4 lg:mt-0">
                  <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">
                    EMAIL
                  </h2>
                  <span className="text-quinary leading-relaxed">
                    click{" "}
                    <a
                      href="/"
                      alt="click here to email us about becoming a partner or stockist"
                    >
                      here
                    </a>{" "}
                    to email us today
                  </span>
                  <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">
                    PHONE
                  </h2>
                  <p className="leading-relaxed">01628 243041</p>
                </div>
              </div>
            </div>
            <div className="lg:w-1/3 md:w-1/2 bg-white flex flex-col md:ml-auto w-full mt-8 sm:mt-0">
              <h2 className="text-green-gradient text-lg mb-1 font-medium title-font">
                Partnership form
              </h2>
              <p className="leading-relaxed mb-5 text-gray-600">
                Please complete the form below to apply to become a partner with
                Intact Electrical. Tell us a bit about your proposal and how it
                could benefit us both.
              </p>
              <form onSubmit={manageSubmit} noValidate>
                  <label  className="font-magistral font-normal text-quaternary text-[16px]" htmlFor="name">
                    Company
                  </label>
                  <input
                    id="company"
                    type="text"
                    name="company"
                    placeholder="Enter your company..."
                    onChange={handleChange}
                    value={values.company}
                    className="text-body-color border-[f0f0f0] focus:border-[#92C2DD] focus:ring-1 focus:ring-[#92C2DD] w-full rounded border py-3 px-[14px] text-base outline-none focus-visible:shadow-none mt-2 mb-2"
                  />
                  {errors.company && (
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-2" role="alert">                     
                      <span className="block sm:inline">{errors.company}</span>                      
                    </div>
                  )}
                  <label className="font-magistral font-normal text-quaternary text-[16px]" htmlFor="name">
                    Contact name
                  </label>
                  <input
                    id="contact"
                    type="text"
                    name="contact"
                    onChange={handleChange}
                    value={values.contact}
                    placeholder="Enter a contact..."
                    className="text-body-color border-[f0f0f0] focus:border-[#92C2DD] focus:ring-1 focus:ring-[#92C2DD] w-full rounded border py-3 px-[14px] text-base outline-none focus-visible:shadow-none mt-2 mb-2"
                  />
                  {errors.contact && (
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-2" role="alert">                     
                      <span className="block sm:inline">{errors.contact}</span>                      
                    </div>
                  )}
                  <label className="font-magistral font-normal text-quaternary text-[16px]" htmlFor="name">
                    Email
                  </label>
                  <input
                    id="email"
                    type="email"
                    name="email"
                    onChange={handleChange}
                    value={values.email}
                    placeholder="Enter your email..."
                    className="text-body-color border-[f0f0f0] focus:border-[#92C2DD] focus:ring-1 focus:ring-[#92C2DD] w-full rounded border py-3 px-[14px] text-base outline-none focus-visible:shadow-none mt-2 mb-2"
                  />
                  {errors.email && (
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-2" role="alert">                     
                      <span className="block sm:inline">{errors.email}</span>                      
                    </div>
                  )}
                  <label className="font-magistral font-normal text-quaternary text-[16px]" htmlFor="name">
                    Message
                  </label>
                  <textarea
                    id="message"
                    type="text"
                    name="message"
                    rows="5"
                    onChange={handleChange}
                    value={values.message}
                    placeholder="Your partnership proposal..."
                    className="text-body-color border-[f0f0f0] focus:border-[#92C2DD] focus:ring-1 focus:ring-[#92C2DD] w-full resize-none rounded border py-3 px-[14px] text-base outline-none focus-visible:shadow-none mt-2 mb-2"
                  />
                  {errors.message && (
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-6" role="alert">                     
                      <span className="block sm:inline">{errors.message}</span>                      
                    </div>
                  )}
                  <button
                    type="submit"
                    className={`py-2 px-4 font-medium text-[18px] bg-dark-grey-radial-gradient text-[#ffffff] rounded-[10px] outline-none ${styles} hover:text-quinary w-full`}
                    style={{ backgroundColor: "#617F7F", color: "#FFFFFF" }}
                    disabled={state.submitting}
                  >
                    Submit your message
                  </button>
      
                  {state.succeeded && (                   
                    <div className="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md mt-4" role="alert">
                    <div className="flex">
                      <div className="py-1"><svg className="fill-current h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
                      <div>
                        <p className="font-bold">Thank you {username}</p>
                        <p className="text-sm">your message has been sent successfully!.</p>
                      </div>
                    </div>
                  </div>
                  )}
                </form>
              {/* <div className="relative mb-4">
                <label
                  htmlFor="name"
                  className="leading-7 text-sm text-gray-600"
                >
                  Contact name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="w-full bg-white rounded border border-gray-300 focus:border-green-500 focus:ring-2 focus:ring-green-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
              </div>
              <div className="relative mb-4">
                <label
                  htmlFor="email"
                  className="leading-7 text-sm text-gray-600"
                >
                  Company email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="w-full bg-white rounded border border-gray-300 focus:border-green-500 focus:ring-2 focus:ring-green-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
              </div>
              <div className="relative mb-4">
                <label
                  htmlFor="Website"
                  className="leading-7 text-sm text-gray-600"
                >
                  Company website
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="w-full bg-white rounded border border-gray-300 focus:border-green-500 focus:ring-2 focus:ring-green-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
              </div>
              <div className="relative mb-4">
                <label
                  htmlFor="message"
                  className="leading-7 text-sm text-gray-600"
                >
                  Partnership proposal
                </label>
                <textarea
                  id="message"
                  name="message"
                  className="w-full bg-white rounded border border-gray-300 focus:border-green-500 focus:ring-2 focus:ring-green-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                ></textarea>
              </div>
              <Button label="Submit Form" styles={`mt-6`} />
              <p className="text-xs text-green-gradient semi-bold mt-3 text-center">
                Intact Electrical Ltd, all rights reserved
              </p> */}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default GetInTouch;
